import { buscaCarteiras } from '../../api/ezcale/carteiras';

const handleBuscaCarteiras = async (buscaCarteirasData, setIsLoading, setAlerta, setCarteiras, setTodasCarteiras) => {

    setIsLoading(true);
    const response = await buscaCarteiras(buscaCarteirasData);
    if (response) { //Caso as Carteiras tenham sido criadas
        setIsLoading(false);
        const reverseArray = [...response].reverse();
        setCarteiras(reverseArray);
        setTodasCarteiras(reverseArray);
    }
    else { //Caso de erro na Criação
        setAlerta({
            type: 'error',
            titulo: 'Não foi possível buscar as Carteiras no momento',
            descricao: 'Um Erro aconteceu, tente novamente!'
        });
        setTimeout(() => {
            setIsLoading(false);
        }, 1000)
    }
};

export default handleBuscaCarteiras;