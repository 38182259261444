import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import ForgotPassword from './ForgotPassword';
import Loader from '../../components/Loader/Loader';
import { Modal } from '@mui/material';
import { login } from '../../api/ezcale/auth';
import { useNavigate } from "react-router-dom";
import { getLogoUrl } from '../../api/ezcale/marca';

export default function Login() {
  const navigate = useNavigate();

  const [userError, setUserError] = useState(false);
  const [userErrorMessage, setUserErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    setLogoUrl(getLogoUrl());
    setIsLoading(false);
  }, [])

  const handleClose = () => {
    setOpen(false);
  };

  const validateInputs = async () => {
    setLoginError('');
    const username = document.getElementById('usuario').value;
    const password = document.getElementById('password').value;

    let isValid = true;

    if (!username) {
      setUserError(true);
      setUserErrorMessage('O campo usuário é obrigatório');
      isValid = false;
    } else {
      setUserError(false);
      setUserErrorMessage('');
    }

    if (!password) {
      setPasswordError(true);
      setPasswordErrorMessage('O campo senha é obrigatório');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (isValid) {
      setIsLoading(true);
      setTimeout(async () => {
        const response = await login({
          username,
          password
        });
        //Caso Negativo da API -> Mostrar Erros
        if (!response) {
          setLoginError('Não foi possível realizar o Login, tente novamente!')
          setIsLoading(false);
        }
        else { //Finaliza o Login e redireciona para Home
          navigate('/');
        }
      }, 200);
    }
  }

  return (
    <Modal open={true}>
      <Box className="w-full h-full h-screen flex items-center justify-center p-3" >
        <Box className="bg-white rounded-xl border border-black shadow-xl flex flex-col self-center w-full p-8 gap-8 mx-auto sm:max-w-lg shadow-[0px_5px_15px_0px_hsla(220,_30%,_5%,_0.05),_0px_15px_35px_-5px_hsla(220,_25%,_10%,_0.05)] dark:shadow-[0px_5px_15px_0px_hsla(220,_30%,_5%,_0.5),_0px_15px_35px_-5px_hsla(220,_25%,_10%,_0.08)]">
          <img className="w-full max-h-44	object-contain mb-[10px]"
            alt="CSI App Logo"
            src={logoUrl} />
          <Box
            component="form"
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
              marginRight: 0
            }}
          >
            <FormControl>
              <FormLabel sx={{ textAlign: 'left' }} htmlFor="usuario">Usuário</FormLabel>
              <TextField
                error={userError}
                helperText={userErrorMessage}
                id="usuario"
                type="usuario"
                name="usuario"
                autoComplete="username"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={userError ? 'error' : 'primary'}
              />
            </FormControl>
            <FormControl>
              {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Link
                component="button"
                onClick={handleClickOpen}
                variant="body2"
                sx={{ alignSelf: 'baseline' }}
              >
                Esqueceu sua senha?
              </Link>
            </Box> */}
              <FormLabel sx={{ textAlign: 'left' }} htmlFor="senha">Senha</FormLabel>
              <TextField
                error={passwordError}
                helperText={passwordErrorMessage}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={passwordError ? 'error' : 'primary'}
              />
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Manter Conectado Nesse Dispositivo"
            />
            <ForgotPassword open={open} handleClose={handleClose} />
            {/* <ImovelPayLoader /> */}
            <Loader isLoading={isLoading} />
            {loginError && <Alert severity="warning">{loginError}</Alert>}
            <Button
              type="submit"
              fullWidth
              disabled={isLoading}
              variant="contained"
              onClick={validateInputs}
            >
              Login
            </Button>

          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
