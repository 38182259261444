import { criaCobranca, getDetalhesDaCobranca } from '../../api/ezcale/cobrancas';

const handleCriaCobrancaRapida = async (cobrancaData, setIsLoading, setAlerta, navigate) => {
    setIsLoading(true);

    //Adiciona Campos vindos da Marca e Seleção do EC
    cobrancaData.cpf_cnpj_adm = '26388193000193'; //TODO PEGAR ESSE CNPJ DA MARCA
    cobrancaData.nome_credor = localStorage.getItem('customerUserName');
    cobrancaData.cpf_cnpj_credor = localStorage.getItem('customerUserTaxId');

    let response = false;
    if (cobrancaData.url_faleconosco !== '' && cobrancaData.url_faleconosco.includes('(')) {
        //Formata Telefone para ficar somente números
        cobrancaData.url_faleconosco = '55' + cobrancaData.url_faleconosco.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
    }

    response = await criaCobranca(cobrancaData);
    if (response) { //Caso as Carteiras tenham sido criadas
        if (response[0]?.contract.hashId) {
            setAlerta({
                type: 'success',
                titulo: 'As Cobranças foram Geradas com Sucesso!',
                descricao: ''
            });
            const cobrancaDetails = await getDetalhesDaCobranca(response[0].contract.hashId);
            const hashInvoiceGroup = cobrancaDetails[0]?.hashInvoiceGroupId;
            setTimeout(() => {
                setAlerta({});
                if(hashInvoiceGroup) {
                    navigate(`/cobrancas/${hashInvoiceGroup}`);
                }
                else {
                    setIsLoading(false);
                }
            }, 5000)
        }
        else { //Caso de erro na Criação
            setAlerta({
                type: 'error',
                titulo: 'As Cobranças não foram Criadas',
                descricao: 'Um Erro aconteceu, tente novamente'
            });
            setTimeout(() => {
                setIsLoading(false);
            }, 1000)
        }
    }
    else { //Caso de erro na Criação
        setAlerta({
            type: 'error',
            titulo: 'As Cobranças não foram Criadas',
            descricao: 'Um Erro aconteceu, tente novamente'
        });
        setTimeout(() => {
            setIsLoading(false);
        }, 1000)
    }
}

export default handleCriaCobrancaRapida;