import axios from 'axios';
import { getBearerTokenConfig } from './auth';

// Lista os Customers embaixo do Usuário logado
async function listaCustomers(limit = 50, offset = 0) {
    const customersData = {
        limit,
        offset,
        action: 'customerslist',
    }

    const endpoint = localStorage.getItem('portalDomain') + '/api/v2' ?? process.env.REACT_APP_API_EZCALE;

    try {
        const { data } = await axios.post(endpoint + "/customers.php", 
            customersData,
            getBearerTokenConfig());
        if (data.status_code === 200) {
            return data.data;
        }
        return [];
    }
    catch {
        return [];
    }
}

export {
    listaCustomers
}