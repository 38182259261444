import React, { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { validateJWT } from '../api/ezcale/auth';
import CircularLoaderWithCenterLogo from '../components/Loader/CircularLoaderWithCenterLogo'

const ProtectedRoute = () => {
    const [authStatus, setAuthStatus] = useState({
        loading: true,
        isAuthenticated: false,
    });
    const [logo, setLogo] = useState('');

    useEffect(() => {
        checkAuth();
    }, []);

    const checkAuth = async () => {
        try {
            const token = localStorage.getItem('user_token');
            setLogo(localStorage.getItem('portalDomainLogo'));

            if (!token) throw new Error('No token found');

            // Simulating API validation of token (replace with real API call)
            const response = await validateJWT();
            
            if (response) {
                setAuthStatus({ loading: false, isAuthenticated: true });
            } else {
                setAuthStatus({ loading: false, isAuthenticated: false });
                throw new Error('Authentication failed');
            }
        } catch (error) {
            setAuthStatus({ loading: false, isAuthenticated: false });
        }
    };

    // Show loading state while checking authentication
    if (authStatus.loading) {
        return <div className="w-full h-full flex items-center justify-center">
            <CircularLoaderWithCenterLogo logoSrc={logo} />
        </div>
    }

    // If authenticated, render the protected routes, otherwise redirect to login
    return authStatus.isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
