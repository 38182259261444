import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { getColorProfile } from '../../utils/appColors';
import { Button, TextField, Typography, Box, InputAdornment } from '@mui/material';
import Loader from '../../components/Loader/Loader';
import handleCriaSegundaVia from './SegundaViaService';
import { retornaDataDeHojeAdicionando7Dias } from '../../utils/dateFunctions';
import { darBaixaEmCobranca } from '../../api/ezcale/cobrancas';

const DarBaixarModal = ({ open, handleClose, cobranca }) => {
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [alerta, setAlerta] = useState({}); //Objeto Alerta
  const [motivo, setMotivo] = useState('')

  useEffect(() => {
    if(motivo !== '') {
      setIsCreateDisabled(false);
    }
  }, [motivo, cobranca]);
  const {
    corPrincipalEscurecida } = getColorProfile();

  const handleBaixaCobranca = async () => {
    setIsLoading(true);
    console.log('cobranca', cobranca);
    console.log('motivo', motivo);
    darBaixaEmCobranca([cobranca.hashInvoiceId], motivo).then(res => {
      window.location.reload();
    })
      .catch(err => {
        setAlerta({
          type: 'error',
          titulo: 'Não foi Possível Baixar a Cobrança',
          descricao: 'Um Erro Aconteceu, tente novamente!'
        })
        setIsLoading(false);
      })

  }

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${open ? '' : 'hidden'}`}>
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-5 mx-3 overflow-auto max-h-[90vh]">
        <Typography id="modal-title" variant="h5" component="h5" sx={{ color: corPrincipalEscurecida }} >
          Dar Baixa em Cobrança
        </Typography>
        <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-2" />
        <Typography
          variant="p"
          component="p"
          sx={{ color: corPrincipalEscurecida, mb: 2 }} >
          Selecione o Motivo e clique em "Baixar" para que a Fatura seja Baixada (Cancelada).
        </Typography>
        <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-5" />
        <Box className="mb-3">
          <TextField
            type="text"
            label="Motivo"
            value={motivo}
            onChange={(e) => setMotivo(e.target.value)}
            className="border rounded-lg w-full"
            slotProps={{
              inputLabel: { shrink: true }
            }}
          />
        </Box>
        <hr className='mt-2 mb-2' />
        {/* Mostra Alerta de Resultado do Processamento */}
        {Object.keys(alerta).length > 0 && (
          <Alert className="signin-area" severity={alerta.type}>
            <AlertTitle>{alerta.titulo}</AlertTitle>
            {alerta.descricao}
          </Alert>)}

        {/* Botões de Ação */}
        {!isLoading ? (
          <div className="flex justify-end space-x-4 mt-5">
            <Button
              sx={{
                background: "linear-gradient(to right, #f87171, #ef4444)", // Botão cancelar vermelho
                color: "white",
                borderRadius: "6px", // Border radius de 6px
                height: "35px",
                mr: 1,
              }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              disabled={isCreateDisabled}
              onClick={handleBaixaCobranca}
              sx={{
                background: "linear-gradient(to right, #66bb6a, #43a047)", // Botão criar
                color: "white",
                borderRadius: "6px", // Border radius de 6px
                height: "35px",
              }}
            >
              Baixar
            </Button>
          </div>)
          :
          (
            <Box className="w-full mt-5" sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Loader isLoading={isLoading} />
            </Box>
          )}

      </div>
    </div>
  );
};

export default DarBaixarModal;
