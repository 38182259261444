import React from 'react';
import { Box } from '@mui/material';

const TextWithDoubleBorder = ({ text, fontSize = 20, innerColor = 'black', outerColor = 'white', borderWidth = 0.5 }) => {
  return (
    <Box
      sx={{
        fontSize: `${fontSize}px`, // Tamanho dinâmico do texto
        color: innerColor, // Cor do texto interno
        position: 'relative',
        display: 'inline-block',
        textShadow: `
          -${borderWidth + 0.5}px -${borderWidth + 0.5}px 0 ${outerColor},
          ${borderWidth + 0.5}px -${borderWidth + 0.5}px 0 ${outerColor},
          -${borderWidth + 0.5}px ${borderWidth + 0.5}px 0 ${outerColor},
          ${borderWidth + 0.5}px ${borderWidth + 0.5}px 0 ${outerColor},
          -${borderWidth}px -${borderWidth}px 0 white,
          ${borderWidth}px -${borderWidth}px 0 white,
          -${borderWidth}px ${borderWidth}px 0 white,
          ${borderWidth}px ${borderWidth}px 0 white`,
      }}
    >
      <b>{text}</b>
    </Box>
  );
};

export default TextWithDoubleBorder;
