import axios from 'axios';
import { getBearerTokenConfig } from './auth';

async function buscaCarteiras(buscaCarteirasData) {

    const endpoint = localStorage.getItem('portalDomain') + '/api/v2' ?? process.env.REACT_APP_API_EZCALE;

    buscaCarteirasData = {
        secret_key: process.env.REACT_APP_API_EZCALE_SECRET_KEY,
        action: 'invoicegroupslist',
        ...buscaCarteirasData,
    }
    try {
        const { data } = await axios.post(endpoint + "/invoices.php",
            buscaCarteirasData,
            getBearerTokenConfig());
        if (data.status_code === 200) {
            return data.data;
        }
        return [];
    }
    catch {
        return [];
    }
}

export {
    buscaCarteiras
}