import axios from 'axios';

async function downloadExtratoDivida(hashInvoiceId) {

    const endpoint = process.env.REACT_APP_API_BACKEND_CSI + '/export/pdfExtratoDivida/' + hashInvoiceId;
    try {
        const jwt = localStorage.getItem('user_token');
        const response = await axios.get(endpoint, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/pdf',
            },
            responseType: 'blob', // Configura para esperar uma resposta em formato Blob
        });

        // Verifica se a resposta foi bem-sucedida
        if (response.status === 200) {
            const blob = response.data; // Aqui você tem o PDF em formato Blob
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'extrato_divida.pdf'; // Nome do arquivo para o download
            link.click(); // Dispara o download
            window.URL.revokeObjectURL(url); // Limpa o objeto URL criado
        } else {
            console.error('Falha ao baixar o arquivo');
        }
    } catch (error) {
        console.error('Erro ao realizar o download:', error);
    }
}

export {
    downloadExtratoDivida
}