import axios from 'axios';

async function buildMarcaApp() {
    const fullUrl = window.location.href;

    const marcaInfoData = {
        secret_key: process.env.REACT_APP_API_EZCALE_SECRET_KEY,
        action: 'info',
        portalDomain: formatUrl(fullUrl),
        httpsPortalDomain: 'https://' + formatUrl(fullUrl)
    }

    try {
        const { data } = await axios.post(process.env.REACT_APP_API_EZCALE + "/login.php", marcaInfoData);
        if (data.status_code === 200) {
            const portalName = data.data.params.portalName.val;
            const portalLogo = data.data.portalDomainLogo;
            localStorage.setItem('portalDomainLogo', portalLogo); //Logo que será utilizada em diversos locais do Site
            localStorage.setItem('portalDomain', data.data.portalDomain);
            
            const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/png';
            link.rel = 'icon';
            link.href = portalLogo;
            document.getElementsByTagName('head')[0].appendChild(link);

            const appleLink = document.querySelector("link[rel*='apple-touch-icon']") || document.createElement('link');
            appleLink.type = 'image/png';
            appleLink.rel = 'icon';
            appleLink.href = portalLogo;
            document.getElementsByTagName('head')[0].appendChild(appleLink);
            
            // Mudar o título da página
            document.title = portalName + ' App';
            return true;
        }
        return false;
    }
    catch {
        return false;
    }
}

function getLogoUrl() {
    return localStorage.getItem('portalDomainLogo');
}

function formatUrl(url) {

    //Caso Especial para desenvolvimento Local (localhost)
    if (url.includes('localhost')) {
        return 'login.atcsadvogados.com.br';
        // return 'login.csiproperties.com.br/';
        // return 'login.cartoriopay.com';
        return 'login.imovelpay.com.br'
    }

    if(url.includes('atcsadvogados')) {
        return 'login.atcsadvogados.com.br';
    }

    let urlFormatada = url.split('https://');
    if (urlFormatada.length === 0) {
        urlFormatada = url.split('http://');
    }

    urlFormatada = url.split('.');
    delete urlFormatada[0]; //Remove o subdominio

    let portalDomain = 'login';
    urlFormatada.forEach(partesUrl => {
        portalDomain += '.' + partesUrl;
    });

    return portalDomain.replace('/', '');

}


export {
    buildMarcaApp,
    getLogoUrl
}