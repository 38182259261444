import React from "react";
import { Link } from "react-router-dom";

const BotoesGradiente = ({ fromColor, toColor, text, Icon, isDisabled = false, height, link = '/', textColor = 'white', onClick }) => {
    return (
      <Link to={link} className={`w-full text-white rounded-xl shadow-lg 
        flex items-center justify-center align-center transform hover:scale-105 
        transition-all duration-300 ease-in-out`}
      >
        <div
          style={{
            background: `linear-gradient(to right, ${fromColor}, ${toColor})`,
            opacity: isDisabled ? 0.5 : 1,  // Diminui a opacidade se desabilitado
            cursor: isDisabled ? 'not-allowed' : 'pointer'
          }}
          className={`w-full h-[140px] text-white rounded-xl shadow-lg flex items-center 
          justify-center align-center transform hover:scale-101 transition-all duration-300 
          ease-in-out ${height}`}
          onClick={onClick}
        >
          <div className={`flex flex-row items-center justify-center text-center ${height} font-bold text-lg`}>
            <Icon className="mr-2" sx={{color: textColor}} />
            <a style={{color: textColor}}>
              {text}
            </a>
          </div>
        </div >
      </Link>
    );
  };

  export default BotoesGradiente;