import axios from 'axios';
import { getBearerTokenConfig } from '../ezcale/auth';

async function criaCobrancasDeAcordo(acordoPayload) {

    try {
        const { data } = await axios.post(process.env.REACT_APP_API_BACKEND_CSI + "/cobrancas/geraAcordos",
            acordoPayload,
            getBearerTokenConfig());
        if (data.status_code === 200) {
            return data;
        }
        return [];
    }
    catch {
        return {};
    }
}

export {
    criaCobrancasDeAcordo
}