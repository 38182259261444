import { Button } from "@mui/material";

const getInvoiceStatusButton = (invoiceStatus) => {

    let background = "linear-gradient(to right, #437070, #354646)";
    let color = 'white';
    if (invoiceStatus === 'canceled' || invoiceStatus === "Cancelado") {
        background = "linear-gradient(to right, #cc838a, #dc3545)";
    }
    else if(invoiceStatus === "draft" || invoiceStatus === "Rascunho") {
        background = "linear-gradient(to right, #9F9F9F, #818181)";
    }
    else if(invoiceStatus === 'Faturado') {
        background = "linear-gradient(to right, #abee8a, #4caf50)";
        color = 'black'
    }
    else if(invoiceStatus === 'Baixado') {
        background = "linear-gradient(to right, #E6BE4C, #EADB75)";
        color = 'black'
    }
    return (
        <Button
            variant="contained"
            sx={{
                background,
                color,
                borderRadius: "6px",
                padding: "4px 8px",
                fontSize: "0.6rem",
                width: "100%",
                height: "35px"
            }}
        >
            <b>{invoiceStatus}</b>
        </Button>)
};

export default getInvoiceStatusButton;