import { getDetalhesDaCobranca } from '../../api/ezcale/cobrancas';

const buscaDetalhesDaCobranca = async (hashInvoiceId, selectedCobranca) => {

    const response = await getDetalhesDaCobranca(hashInvoiceId);
    if (response) { 
        selectedCobranca(response[0]);
    }
    return null;
};

export { buscaDetalhesDaCobranca };

