import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { getColorProfile } from '../../utils/appColors';

const LogoWithCircularProgress = ({ logoSrc }) => {
    const [logo, setLogo] = useState('');
    const { corSecundaria, corPrincipal } = getColorProfile();

    useEffect(() =>{
        setLogo(localStorage.getItem('portalDomainLogo'));
    }, []);

    return (
        <Box className='w-full h-screen flex items-center justify-center pb-[100px]'>
            {/* Circular Progress Customizado */}
            <Box
                sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {/* Progresso com gradiente e animação de rotação */}
                <Box
                    sx={{
                        position: 'absolute',
                        width: 250,
                        height: 250,
                        borderRadius: '50%',
                        background: `conic-gradient(${corSecundaria} 0%, ${corPrincipal} 100%)`,
                        mask: 'radial-gradient(closest-side, transparent 90%, black 90%)',
                        animation: 'spin 1.4s linear infinite', // Animação de rotação
                        zIndex: 1, // Coloca o spinner atrás da logo
                    }}
                />

                {/* Logo no centro */}
                {(logoSrc || logo) && (<Box
                    component="img"
                    src={logoSrc || logo}
                    alt="Logo"
                    sx={{
                        position: 'relative', // Mantém a logo no topo
                        width: 100, // Tamanho da imagem da logo
                        height: 100,
                        objectFit: 'contain',
                        zIndex: 2, // Coloca a logo na frente do spinner
                    }}
                />)}
            </Box>
        </Box>
    );
};

// Adiciona a keyframes da animação de rotação no estilo global
const styles = `
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`;

// Insere a animação de rotação no documento
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default LogoWithCircularProgress;
