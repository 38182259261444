import { createBrowserRouter } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Carteiras from '../pages/Carteiras/Carteiras';
import Login from '../pages/Login/Login';
import Cobrancas from '../pages/Cobrancas/Cobrancas';
import ProtectedRoutes from './ProtectedRoutes';
import MainLayoutwithNavbar from '../components/MainLayoutwithNavbar';

const Router = createBrowserRouter([
  {
    path: "login",
    element: <Login />, // Login page component
  },
  {
    element: <MainLayoutwithNavbar />,
    children: [
      {
        path: "/",
        element: (<ProtectedRoutes />), //NavBar Compartilhada e validação de Acesso
        // errorElement: <ErrorPage />, // Error page for invalid routes
        children: [
          {
            path: "/",
            element: <Home />, // Home page component
          },
          {
            path: "carteiras",
            element: <Carteiras />,
          },
          {
            path: "cobrancas/:hashId",
            element: <Cobrancas />,
          },
        ]
      }
    ]
  }

]);

export default Router;