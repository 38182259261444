import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar'; // Your Navbar component

const MainLayoutwithNavbar = () => {
    return (
        <div className="min-h-screen pt-16"> {/* Ajuste a altura mínima e o padding superior */}
            <Navbar />
            <div className="p-6"> {/* Adicione padding ao conteúdo */}
                <Outlet />
            </div>
        </div>
    );
};

export default MainLayoutwithNavbar;
