import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import {getColorProfile} from '../../utils/appColors';

function Loader({ isLoading, style }) {
    const {corPrincipal, corPrincipalEscurecida, corSecundaria, corSecundariaEscurecida} = getColorProfile();

    return (
        <div className='w-full'>
            {isLoading &&
                (<Stack sx={{ width: '100%' }} spacing={1}>
                    <LinearProgress sx={{
                        height: '10px',
                        borderRadius: '60px',
                        backgroundColor: corPrincipal,
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: corPrincipalEscurecida
                        }
                    }} />
                    <LinearProgress sx={{
                        height: '10px',
                        borderRadius: '100px',
                        backgroundColor: corSecundaria,
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: corSecundariaEscurecida
                        }
                    }} />
                </Stack>)}
        </div>
    );
}

export default Loader;