function retornaDataDeHojeAdicionando7Dias() {
    // Obter a data de hoje
    const data = new Date();
    
    // Adicionar 7 dias
    data.setDate(data.getDate() + 7);

    // Formatar a data para o formato "yyyy-mm-dd"
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Ajusta o mês para 2 dígitos
    const dia = String(data.getDate()).padStart(2, '0'); // Ajusta o dia para 2 dígitos

    return `${ano}-${mes}-${dia}`;
}

function subtraiXDiasDeHoje(x) {
    // Obter a data de hoje
    const data = new Date();
    
    // Adicionar 7 dias
    data.setDate(data.getDate() - x);

    // Formatar a data para o formato "yyyy-mm-dd"
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Ajusta o mês para 2 dígitos
    const dia = String(data.getDate()).padStart(2, '0'); // Ajusta o dia para 2 dígitos

    return `${ano}-${mes}-${dia}`;
}

function formataDataHoraMySQLParaDataBrasileira(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [year, month, day] = datePart.split("-");

    return `${day}/${month}/${year} ${timePart}`;
}

 // Função para formatar as datas no formato aaaa-mm-dd
 const formatDate = (date) => {
    return date.toISOString().split('T')[0]; // ISO string no formato yyyy-mm-dd
  };
  



export {
    retornaDataDeHojeAdicionando7Dias,
    formataDataHoraMySQLParaDataBrasileira,
    formatDate,
    subtraiXDiasDeHoje
}