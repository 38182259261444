import axios from 'axios';
import { getBearerTokenConfig } from './auth';

const endpoint = localStorage.getItem('portalDomain') + '/api/v2' ?? process.env.REACT_APP_API_EZCALE;
// const endpoint = process.env.REACT_APP_API_EZCALE;

async function buscaCobrancas(hashId, datefrom = null, dateto = null) {
    let buscaCobrancasData = {
        datefrom, 
        dateto,
        action: 'invoiceslist'        
    }
    if (hashId !== 'all') {
        buscaCobrancasData.hashInvoiceGroupId = hashId
    }
    if (!datefrom) {
        buscaCobrancasData = {
            ...buscaCobrancasData,
            datefrom: '2023-01-01'
        }
    }
    if (!dateto) {
        buscaCobrancasData = {
            ...buscaCobrancasData,
            dateto: '2030-10-15'
        }
    }
 
    try {
        const { data } = await axios.post(endpoint + "/invoices.php",
            buscaCobrancasData,
            getBearerTokenConfig());
        if (data.status_code === 200) {
            return data.data;
        }
        return [];
    }
    catch {
        return [];
    }
}

async function getDetalhesDaCobranca(hashInvoiceId) {

    try {
        const config = getBearerTokenConfig();
        config.params = {
            action: "invoicedetails",
            hashInvoiceId
        }
        const { data } = await axios.get(endpoint + "/invoices.php", config);
        if (data.status_code === 200) {
            return data.data;
        }
        return [];
    }
    catch {
        return {};
    }
}

async function criaCobranca(cobrancaData, hashCarteira = null) {
    if (cobrancaData.tipo_cobranca == undefined ||
        cobrancaData.tipo_cobranca == null ||
        cobrancaData.tipo_cobranca == ''
    ) {
        cobrancaData.tipo_cobranca = 'cobranca_extrajudicial';
    }
    const criaCobrancaData = {
        action: "createinvoice",
        payload: [cobrancaData]
    };
    if (hashCarteira !== null) {
        criaCobrancaData['invoiceGroup'] = hashCarteira;
    }
    const { data } = await axios.post(endpoint + "/invoices.php",
        criaCobrancaData,
        getBearerTokenConfig());
    if (data.status_code === 200) {
        return data.data;
    }
    return [];

}

async function darBaixaEmCobranca(arrayDeCobrancas, motivo) {

    const criaCobrancaData = {
        "action": "invoicemanualclearance",
        "hashInvoiceArray": arrayDeCobrancas,
        "clearanceDetail": motivo
    };
    const { data } = await axios.post(endpoint + "/invoices.php",
        criaCobrancaData,
        getBearerTokenConfig());
    if (data.status_code === 200) {
        return data.data;
    }
    return [];

}

export {
    buscaCobrancas,
    getDetalhesDaCobranca,
    criaCobranca,
    darBaixaEmCobranca
}